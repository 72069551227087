<div class="content" fxLayout="column">
  <!-- SEARCH -->
  <div style="width: 100%" fxLayout="row wrap" fxLayoutAlign="center">
    <div class="table-search mb-16" fxLayoutAlign="center center" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
      <div class="center" style="width: 100%; justify-content: center; display: flex; align-items: center; padding-bottom: 5px; padding-top: 10px">
        <div class="search-wrapper" style="box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15); background: white; min-width: 240px; max-width: 350px; display: inline-block">
          <div
            class="search"
            fxFlex
            fxLayout="row"
            fxLayoutAlign="start center"
            style="color: grey; flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-start; align-items: center; flex: 1 1 0%"
          >
            <mat-icon color="accent">search</mat-icon>
            <input #filter (change)="filterTemplates()" placeholder="Search Templates" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / SEARCH -->
  <div fxLayout="row wrap" fxLayoutAlign="center" fxFlex="100" *ngIf="isLoading" style="width: 100%; background: white; padding-bottom: 20px">
    <div class="untemplate" *ngFor="let untemplate of templateList" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="20" style="padding: 16px">
      <ngx-skeleton-loader class="untemplate-content"
        fxLayout="row wrap" fxLayoutAlign="center"
        [theme]="{
          height: '200px',
          width: '150px',
          'border-radius': '8px',
          background: 'rgb(140 137 137)'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader class="untemplate-content"
        fxLayout="row wrap" fxLayoutAlign="center"
        [theme]="{
          height: '18px',
          width: '150px',
          'margin-top': '10px',
          background: 'rgb(140 137 137)'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>

  <div *ngIf="!isLoading">
    <div style="width: 100%" fxLayout="row wrap" fxLayoutAlign="center">
      <div class="untemplates" fxLayout="row wrap" fxLayoutAlign="center" style="text-align: center; width: 100%">
        <!--Empty template start-->
        <div class="untemplate" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="20" style="padding-left: 5px; padding-right: 5px; padding-top: 16px; padding-bottom: 16px" *ngIf="pageIndex == 0">
          <div class="untemplate-content" fxLayout="column wrap" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
            <div (click)="onTemplateClick.emit(undefined)" fxLayout="row " fxLayoutAlign="start" style="min-height: 232px; max-height: 232px; overflow: hidden; background: #f5f5f5"></div>
            <div fxLayout="row " class="p-16 mt-12" style="display: flex; width: 100%">
              <div style="text-align: left; width: 100%" (click)="onTemplateClick.emit(undefined)" class="h4 bold">Empty Template</div>
              <!--                  <div style="float: right;cursor: pointer;">-->
              <!--                    <button-->
              <!--                            [disabled]="untemplate.orgId != orgId"-->
              <!--                            style="border: none; background: none;"-->
              <!--                            (click)="deleteTemplate(untemplate)"-->
              <!--                    >-->
              <!--                      <mat-icon style="color: #FF8845">delete</mat-icon>-->
              <!--                    </button>-->
              <!--                  </div>-->
            </div>
          </div>
        </div>
        <!--Empty template end-->
        <div class="untemplate" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="20" style="padding-left: 5px; padding-right: 5px; padding-top: 16px; padding-bottom: 16px" *ngIf="pageIndex == 0">
          <div class="untemplate-content" fxLayout="column wrap" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
            <div (click)="onTemplateClick.emit('raw')" fxLayout="row " fxLayoutAlign="start" style="min-height: 232px; max-height: 232px; overflow: hidden; background: #f5f5f5"></div>
            <div fxLayout="row " class="p-16 mt-12" style="display: flex; width: 100%">
              <div style="text-align: left; width: 100%" (click)="onTemplateClick.emit('raw')" class="h4 bold">HTML template</div>
              <!--Raw Template Ends here-->
            </div>
          </div>
        </div>
        <!--raw template end-->

        <div
          class="untemplate"
          *ngFor="let untemplate of templateList"
          fxFlex="100"
          fxFlex.gt-xs="50"
          fxFlex.gt-sm="20"
          style="padding-left: 5px; padding-right: 5px; padding-top: 16px; padding-bottom: 16px"
        >
          <div class="untemplate-content" fxLayout="row wrap" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-sm="100">
            <div (click)="onTemplateClick.emit(untemplate.unjson)" fxLayout="row " fxLayoutAlign="start" style="min-height: 200px; max-height: 200px; overflow: hidden">
              <img [src]="getThumbnailImg(untemplate)" />
            </div>
            <div fxLayout="row " class="p-16" style="display: flex; width: 100%; justify-content: center; align-items: center">
              <div style="text-align: left; width: 100%" (click)="onTemplateClick.emit(untemplate.unjson)" class="h4 bold">
                {{ untemplate.name | titlecase }}
              </div>
              <div fxFlex fxLayoutAlign="center" style="float: right;">
                <button mat-icon-button
                  *ngIf="untemplate.orgId == orgId"
                  (click)="deleteTemplate(untemplate)"
                >
                  <mat-icon style="color: #FF8845">delete</mat-icon>
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" #paginator [pageSizeOptions]="pageSizeOptions" (page)="getTemplatesPagination($event)"> </mat-paginator>
  </div>
</div>
